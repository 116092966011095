import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"
import { useIntl } from "gatsby-plugin-react-intl"
import ExpertsSection from "../../components/ExpertsSection"
import WhySection from "../../components/WhySection"
import RequestButton from "../../components/RequestButton"

const WorkCompPage = ({ data: { page, bannerImage, sectionImages } }) => {
  const intl = useIntl()
  const pluginImage = getImage(bannerImage.childImageSharp.gatsbyImageData)
  const image = convertToBgImage(pluginImage)
  return (
    <Layout>
      <h1 className="hidden">{page?.title}</h1>
      <Seo seoData={page?.seo} />
      <BackgroundImage
        Tag="div"
        // Spread bgImage into BackgroundImage:
        {...image}
        preserveStackingContext
        className="bg-fixed bg-top bg-black bg-opacity-50 bg-blend-multiply flex justify-center items-center"
      >
        <h2 className="text-white text-5xl lg:text-6xl font-PTSerif font-bold italic py-20 text-center">
          {intl.formatMessage({ id: "Workers’ Compensation" })}
        </h2>
      </BackgroundImage>
      <ExpertsSection />
      <div className="w-10/12 mx-auto grid grid-cols-1 gap-5">
        <div className="py-5">
          {/* <h3 className="font-PTSerif font-bold text-themePrimary text-2xl pb-3">
            {intl.formatMessage({ id: "practiceArea2" })}
          </h3> */}
          <GatsbyImage
            image={sectionImages?.nodes[0].childImageSharp?.gatsbyImageData}
            alt="Injured Workers Compensation Attorney Long Island"
            className="float-left mr-3 mb-3"
          />
          <p className="pb-3">{intl.formatMessage({ id: "workComp1" })}</p>
          <ul className="pb-3 list-inside list-disc">
            <li className="pb-2">{intl.formatMessage({ id: "wc2Point1" })}</li>
            <li className="pb-2">{intl.formatMessage({ id: "wc2Point2" })}</li>
            <li className="pb-2">{intl.formatMessage({ id: "wc2Point3" })}</li>
            <li className="pb-2">{intl.formatMessage({ id: "wc2Point4" })}</li>
            <li className="pb-2">{intl.formatMessage({ id: "wc2Point5" })}</li>
            <li className="pb-2">{intl.formatMessage({ id: "wc2Point6" })}</li>
            <li className="pb-2">{intl.formatMessage({ id: "wc2Point7" })}</li>
            <li className="pb-2">{intl.formatMessage({ id: "wc2Point8" })}</li>
          </ul>
          <p className="pb-3">{intl.formatMessage({ id: "workComp3" })}</p>
          <p className="pb-3">{intl.formatMessage({ id: "workComp4" })}</p>
          <p className="pb-3">{intl.formatMessage({ id: "workComp5" })}</p>
        </div>
        <div className="py-5">
          <h3 className="font-PTSerif font-bold text-themePrimary text-2xl pb-3">
            {intl.formatMessage({ id: "workComp6" })}
          </h3>
          <GatsbyImage
            image={sectionImages?.nodes[1].childImageSharp?.gatsbyImageData}
            alt="Workers’ Compensation Attorney Long Island NY"
            className="mb-3 float-right ml-3"
          />
          <div>
            <p className="pb-3">{intl.formatMessage({ id: "workComp7" })}</p>
            <p className="pb-3">{intl.formatMessage({ id: "workComp8" })}</p>
            <p className="pb-12">{intl.formatMessage({ id: "workComp9" })}</p>
            <RequestButton />
          </div>
        </div>
      </div>
      <WhySection />
    </Layout>
  )
}

export default WorkCompPage

export const pageQuery = graphql`
  query WorkCompPageById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
  ) {
    # selecting the current post by id
    page: wpPage(id: { eq: $id }) {
      id
      content
      title
      slug
      seo {
        fullHead
        schema {
          raw
        }
      }
    }
    bannerImage: file(
      name: { regex: "/-banner-/" }
      relativeDirectory: { eq: "practice" }
    ) {
      name
      childImageSharp {
        gatsbyImageData(
          width: 1400
          placeholder: BLURRED
          quality: 90
          formats: [AUTO, WEBP]
        )
      }
    }
    sectionImages: allFile(
      filter: {
        name: { regex: "/-section-/" }
        relativeDirectory: { eq: "work-comp" }
      }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        id
        name
        childImageSharp {
          gatsbyImageData(
            width: 550
            placeholder: BLURRED
            quality: 90
            formats: [AUTO, WEBP]
          )
        }
      }
    }
  }
`
